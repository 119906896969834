import { createApp } from '@neos/app'
import { views } from './views'
import { menuItems } from './menuItems'
import images from './images'
import '@neos/design-system/dist/style.css'
import '@neos/app/dist/style.css'
import '@neos/ai/dist/style.css'

const resources = import.meta.glob<boolean, string, { default: Record<string, unknown> }>(`./resources/*.ts`)

const themes = import.meta.glob<boolean, string, string | undefined>('./themes/*.css', {
  query: '?inline',
  import: 'default',
})

createApp({
  production: import.meta.env.PROD,
  name: `TrackingDemo`,
  title: `Tracking Demo`,
  version: `2.1.7`,
  company: `Groupe Isagri Services`,
  languages: [`en`, `fr`],
  importResources: (lang) => resources[`./resources/${lang}.ts`],
  themes: [{ name: 'NeosV1', colorScheme: 'light', animatedButtons: false }, { name: 'NeosV2', colorScheme: 'light', animatedButtons: false }, { name: 'NeosV3', colorScheme: 'light', animatedButtons: true }],
  importTheme: (theme) => themes[`./themes/${theme}.css`]?.(),
  hotModuleReload: import.meta.hot,
  views,
  menuItems,
  images,
  initializationMethods: [],
  mainUIViewName: `OrderTrackingUI`,
  uiDefaultBehavior: {},
})